import React, { MutableRefObject, useRef, useState } from 'react'
import styles from '@style/NewsletterSubscribeForm.module.css'
import fetch from 'isomorphic-unfetch'
import Image from 'next/image'

export const enum SubscribeFormPosition {
  Header = 'header',
  Footer = 'footer',
}

type SubscribeFormProps = {
  position?: SubscribeFormPosition
}

export default function NewsletterSubscribeForm({
  position = SubscribeFormPosition.Footer,
}: SubscribeFormProps) {
  // 1. Create a reference to the input so we can fetch/clear it's value.
  const inputEl = useRef<HTMLInputElement>(null)
  // 2. Hold a message in state to handle the response from our API.
  const [message, setMessage] = useState<string | boolean>(
    position == SubscribeFormPosition.Footer &&
      "`We'll only send emails when something big happens. No spam.`"
  )
  const [isError, setIsError] = useState(false)

  const clearField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (message.toString().length > 0) {
      setMessage('')
    }
  }

  const subscribeUser = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    // this is where your mailchimp request is made

    const res = await fetch('/api/subscribe', {
      body: JSON.stringify({
        email: inputEl.current?.value,
      }),

      headers: {
        'Content-Type': 'application/json',
      },

      method: 'POST',
    })

    const { error } = await res.json()

    if (error) {
      console.error(error)
      // 4. If there was an error, update the message in state.
      setIsError(true)
      setMessage(error)

      return
    } else {
      setIsError(false)
    }

    // 5. Clear the input value and show a success message.
    if (inputEl.current) {
      inputEl.current.value = ''
    }
    setMessage('Thanks for subscribing!')
  }

  return (
    <div className={`${styles.newsletterFormContainer} ${styles[position]}`}>
      <form onSubmit={subscribeUser} className={styles.newsletterForm}>
        <label htmlFor="email-input" hidden>
          {'Email Address'}
        </label>
        <input
          id="email-input"
          name="email"
          onChange={clearField}
          placeholder={
            position == SubscribeFormPosition.Header
              ? 'Subscribe to Monofor Blog'
              : 'you@your-company.com'
          }
          ref={inputEl}
          required
          type="email"
          className={styles.newsletterEmailInput}
        />
        {position == SubscribeFormPosition.Header ? (
          <button type="submit" className={styles.newsletterSubmit}>
            <Image
              src="/images/blog-newsletter-arrow.svg"
              width={28}
              height={28}
              alt="Subscribe to Monofor Blog"
            />
          </button>
        ) : (
          <button type="submit" className={styles.newsletterSubmit}>
            Submit →
          </button>
        )}
      </form>
      {message && (
        <div
          className={
            isError ? styles.newsletterError : styles.newsletterMessage
          }
        >
          {message
            ? message
            : position == SubscribeFormPosition.Footer &&
              `We'll only send emails when something big happens. No spam.`}
        </div>
      )}
    </div>
  )
}
