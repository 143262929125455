import Link from 'next/link'
import styles from '@style/Footer.module.css'
import NewsletterSubscribeForm from '@comp/newsletter-subscribe-form'
import MonoforFooterLogo from './icons/monofor-footer-logo'
import Image from 'next/image'
import { useGlobalContext } from '../context/GlobalContext'

const year = new Date().getFullYear()

export default function Footer() {
  const { globalData } = useGlobalContext()

  return (
    <footer id={styles.siteFooter}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLeftColumn}>
          <MonoforFooterLogo />

          <p>
            To connect with a product expert today,{' '}
            <Link href="/contact/">fill our contact form</Link>.
          </p>
          <a href="mailto:sales@monofor.com" className={styles.btnWhite}>
            Contact Us
          </a>
        </div>
        <div className={styles.footerNavLeft}>
          <h6>Product</h6>
          <ul>
            {globalData.features &&
              globalData.features.map((feature: any, index: number) => (
                <>
                  <li>
                    <Link href={'/features/' + feature.slug}>
                      {feature.title}
                    </Link>
                  </li>
                </>
              ))}
          </ul>
        </div>
        <div>
          <h6>Resources</h6>
          <ul>
            <li>
              <Link href="/blog">Blog</Link>
            </li>
            <li>
              <Link href="https://www.youtube.com/@monofor">Videos</Link>
            </li>
            <li>
              <Link href="https://help.monofor.com">Documentation</Link>
            </li>
            <li>
              <Link href="https://brand.monofor.com">Brand Resources</Link>
            </li>
          </ul>
        </div>
        <div className={styles.rightColumn}>
          <h6>Company</h6>
          <ul>
            <li>
              <Link href="/company">About Us</Link>
            </li>
            <li>
              <Link href="/legal/information-security-policy">
                Sustainability
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.newsletterSection}>
        <div className={styles.newsletterTitleContainer}>
          <h6>Join our newsletter</h6>
          <p>Don&apos;t miss any updates from Monofor</p>
        </div>
        <NewsletterSubscribeForm />
      </div>

      <div className={styles.subFooter}>
        <div className={styles.subFooterBody}>
          <div className={styles.socialMedia}>
            <div className={styles.socialMediaButtons}>
              <a
                href="https://www.twitter.com/monoforINC"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_403_41)">
                    <path
                      d="M24 4.55705C23.117 4.94905 22.168 5.21305 21.172 5.33205C22.189 4.72305 22.97 3.75805 23.337 2.60805C22.386 3.17205 21.332 3.58205 20.21 3.80305C19.313 2.84605 18.032 2.24805 16.616 2.24805C13.437 2.24805 11.101 5.21405 11.819 8.29305C7.728 8.08805 4.1 6.12805 1.671 3.14905C0.381 5.36205 1.002 8.25705 3.194 9.72305C2.388 9.69705 1.628 9.47605 0.965 9.10705C0.911 11.388 2.546 13.522 4.914 13.997C4.221 14.185 3.462 14.229 2.69 14.081C3.316 16.037 5.134 17.46 7.29 17.5C5.22 19.123 2.612 19.848 0 19.54C2.179 20.937 4.768 21.752 7.548 21.752C16.69 21.752 21.855 14.031 21.543 7.10605C22.505 6.41105 23.34 5.54405 24 4.55705Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_403_41">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="https://instagram.com/monoforinc"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_403_43)">
                    <path
                      d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_403_43">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/monofor/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_403_45)">
                    <path
                      d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_403_45">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <div className={styles.subFooterNav}>
            <div className={styles.footerCopyright}>
              {'Copyright © ' + year + ' Monofor, Inc. All rights reserved.'}
            </div>
            <Link
              href="/legal/terms-and-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </Link>
            <Link href="/legal/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className={styles.certificateContainer}>
          <div className={styles.isoContainer}>
            <Image
              src="/images/iso-27001.png"
              width={96}
              height={96}
              alt="Monofor is ISO 27001 Certified"
            />
          </div>
        </div>
      </div>
    </footer>
  )
}
