export default function MonoforFooterLogo() {
  return (
    <svg
      width="136"
      height="29"
      viewBox="0 0 136 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_526_327)">
        <mask
          id="mask0_526_327"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="29"
          height="28"
        >
          <path
            d="M28.6674 0.951416H0.602539V27.9998H28.6674V0.951416Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_526_327)">
          <path
            d="M7.86946 4.78877C8.2278 4.67293 8.58614 4.55709 8.95881 4.45573C10.7505 3.96341 12.6425 3.6883 14.5918 3.6883C16.5412 3.6883 18.4332 3.94893 20.2249 4.45573C20.5976 4.55709 20.9559 4.67293 21.3142 4.78877C21.9306 4.99149 22.5182 5.22316 23.1059 5.4838C24.0806 5.9182 25.0123 6.41051 25.9009 6.98971C25.8723 6.29467 25.8149 5.61412 25.7289 4.93357C25.6716 4.51365 25.5999 4.09373 25.5283 3.67382C24.5393 3.15254 23.5072 2.71814 22.4466 2.34167C21.8016 2.10999 21.1422 1.92175 20.4829 1.748C20.0959 1.64664 19.6946 1.55975 19.2932 1.47287C17.7739 1.1688 16.1972 1.00952 14.5918 1.00952C12.9865 1.00952 11.4098 1.1688 9.89048 1.47287C9.48914 1.55975 9.08781 1.64664 8.70081 1.748C8.02713 1.92175 7.38213 2.12447 6.73712 2.34167C5.67645 2.71814 4.64444 3.15254 3.65543 3.67382C3.56943 4.09373 3.49777 4.51365 3.44043 4.93357C3.35443 5.61412 3.2971 6.30915 3.26843 6.98971C4.15711 6.42499 5.08878 5.9182 6.06345 5.4838C6.66546 5.23764 7.25313 5.00597 7.86946 4.78877Z"
            fill="white"
          />
          <path
            d="M27.5923 8.1771C27.0476 7.75718 26.4886 7.35174 25.9153 6.98975C25.9583 8.04678 25.9153 9.11829 25.7863 10.1753C25.7146 10.8124 25.6143 11.4495 25.4853 12.0867C25.4136 12.4631 25.3133 12.8251 25.2273 13.2016C24.7973 14.8668 24.1523 16.4885 23.2923 18.0668C22.2889 19.9058 21.0419 21.5275 19.6372 22.9321C19.3649 23.2072 19.0926 23.4678 18.8059 23.714C18.3186 24.1484 17.8169 24.5538 17.3009 24.9303C16.4409 25.5674 15.5379 26.1321 14.6062 26.6245C15.2225 26.943 15.8389 27.2326 16.4839 27.4932C16.8709 27.6525 17.2722 27.7973 17.6736 27.9421C18.6196 27.334 19.5082 26.6534 20.3682 25.9149C20.8842 25.4661 21.3859 24.9882 21.8589 24.4959C22.1456 24.2063 22.4179 23.9022 22.6903 23.5837C23.7939 22.3095 24.7829 20.9049 25.6429 19.3556C26.331 18.1103 26.89 16.8216 27.3343 15.5184C27.4633 15.1274 27.5923 14.7365 27.707 14.3455C27.8933 13.6795 28.051 13.0134 28.18 12.3328C28.395 11.2179 28.5383 10.1029 28.5813 8.98797C28.2516 8.71285 27.922 8.43774 27.5923 8.1771Z"
            fill="white"
          />
          <path
            d="M10.3921 23.714C10.1054 23.4678 9.8331 23.2072 9.56076 22.9321C8.28509 21.6723 7.15274 20.2099 6.1924 18.5881C5.17473 16.865 4.44373 15.055 3.95639 13.2016C3.85606 12.8396 3.77005 12.4631 3.69839 12.0867C3.56939 11.4495 3.46905 10.8124 3.39738 10.1753C3.28272 9.11829 3.23972 8.04678 3.26838 6.98975C2.69505 7.36622 2.12171 7.75718 1.59137 8.1771C1.2617 8.43774 0.932035 8.71285 0.616699 8.98797C0.674033 10.1029 0.803034 11.2323 1.01804 12.3328C1.14704 13.0134 1.3047 13.6794 1.49104 14.3455C1.6057 14.7365 1.72037 15.1274 1.86371 15.5184C2.37971 17.0388 3.05338 18.5157 3.89905 19.9492C4.67306 21.2669 5.5474 22.4832 6.50774 23.5837C6.78007 23.8878 7.05241 24.1918 7.33908 24.4814C7.82642 24.9738 8.31375 25.4516 8.82976 25.9005C9.68976 26.6389 10.5928 27.3195 11.5244 27.9276C11.9258 27.7973 12.3271 27.638 12.7141 27.4788C13.3448 27.2181 13.9755 26.9285 14.5918 26.61C13.6601 26.1177 12.7571 25.5529 11.8971 24.9158C11.3811 24.5538 10.8794 24.1484 10.3921 23.714Z"
            fill="white"
          />
          <path
            d="M20.9417 16.7637C21.8734 15.055 22.5184 13.2595 22.891 11.4351C22.9627 11.0875 23.02 10.7256 23.0774 10.3636C23.1634 9.7554 23.2207 9.14725 23.2494 8.53909C22.719 8.24949 22.16 7.98886 21.5867 7.75718C21.257 7.62686 20.913 7.49654 20.5834 7.3807C18.7057 6.74359 16.699 6.38159 14.6063 6.38159C12.5136 6.38159 10.507 6.72911 8.62928 7.3807C8.28527 7.49654 7.95561 7.62686 7.62594 7.75718C7.0526 7.98886 6.50793 8.24949 5.96326 8.53909C5.99192 9.14725 6.04926 9.7554 6.13526 10.3636C6.19259 10.7256 6.24993 11.0731 6.32159 11.4351C6.72293 13.4188 7.45393 15.3736 8.52894 17.2125C9.56095 18.9646 10.8366 20.4995 12.2843 21.7737C12.5566 22.0199 12.829 22.2371 13.1156 22.4687C13.603 22.8452 14.1046 23.1927 14.6207 23.5258C15.1367 23.2072 15.6383 22.8452 16.1257 22.4687C16.4123 22.2515 16.6847 22.0199 16.957 21.7737C18.505 20.3836 19.8667 18.704 20.9417 16.7637Z"
            fill="white"
          />
        </g>
        <path
          d="M38.0271 23.106V6.64233H42.7428L46.7275 18.9213H47.2578L51.2855 6.64233H55.9869V23.106H53.0772V11.2469H52.5325L48.6625 23.106H45.3515L41.4815 11.2469H40.9225V23.106H38.0271Z"
          fill="white"
        />
        <path
          d="M69.7184 10.8992C68.4284 9.56703 66.78 8.88647 64.7877 8.88647C62.781 8.88647 61.1327 9.55255 59.8283 10.8992C58.5383 12.2313 57.8933 13.9834 57.8933 16.1409C57.8933 18.2984 58.5383 20.036 59.8283 21.3826C61.1327 22.7148 62.7953 23.3953 64.7877 23.3953C66.7944 23.3953 68.4427 22.7292 69.7184 21.3826C71.0227 20.0505 71.6821 18.2984 71.6821 16.1409C71.6821 13.9834 71.0227 12.2458 69.7184 10.8992ZM67.7404 19.6016C66.9664 20.4559 65.9774 20.8903 64.7877 20.8903C63.598 20.8903 62.5947 20.4559 61.8063 19.6016C61.0323 18.7328 60.6453 17.5744 60.6453 16.1409C60.6453 14.7074 61.0323 13.5635 61.8063 12.7092C62.5947 11.8404 63.5837 11.406 64.7877 11.406C65.9774 11.406 66.9664 11.8404 67.7404 12.7092C68.5287 13.5635 68.9301 14.7074 68.9301 16.1409C68.9157 17.5744 68.5287 18.7183 67.7404 19.6016Z"
          fill="white"
        />
        <path
          d="M73.4736 23.1057V9.17607H75.9533L76.2257 10.5661H76.369C76.6843 10.2476 77.0283 9.97246 77.3867 9.72631C78.2753 9.17607 79.2643 8.88647 80.368 8.88647C82.045 8.88647 83.378 9.42223 84.367 10.4793C85.3847 11.5218 85.8864 12.9408 85.8864 14.7363V23.0912H83.1344V14.7508C83.1344 13.8386 82.819 13.0567 82.174 12.4051C81.5434 11.739 80.7694 11.406 79.8234 11.406C78.8057 11.406 77.96 11.739 77.2577 12.4051C76.584 13.0567 76.24 13.8386 76.24 14.7508V23.1057H73.4736Z"
          fill="white"
        />
        <path
          d="M99.6608 10.8992C98.3708 9.56703 96.7224 8.88647 94.7301 8.88647C92.7234 8.88647 91.075 9.55255 89.7707 10.8992C88.4807 12.2313 87.8357 13.9834 87.8357 16.1409C87.8357 18.2984 88.4807 20.036 89.7707 21.3826C91.075 22.7148 92.7377 23.3953 94.7301 23.3953C96.7368 23.3953 98.3851 22.7292 99.6608 21.3826C100.965 20.0505 101.624 18.2984 101.624 16.1409C101.624 13.9834 100.965 12.2458 99.6608 10.8992ZM97.6828 19.6016C96.9088 20.4559 95.9198 20.8903 94.7301 20.8903C93.5404 20.8903 92.5371 20.4559 91.7487 19.6016C90.9747 18.7328 90.5877 17.5744 90.5877 16.1409C90.5877 14.7074 90.9747 13.5635 91.7487 12.7092C92.5371 11.8404 93.5261 11.406 94.7301 11.406C95.9198 11.406 96.9088 11.8404 97.6828 12.7092C98.4711 13.5635 98.8724 14.7074 98.8724 16.1409C98.8724 17.5744 98.4711 18.7183 97.6828 19.6016Z"
          fill="white"
        />
        <path
          d="M110.053 6.25135H107.974C107.43 6.25135 107.143 6.52647 107.143 7.09119V9.17629H110.038V11.8261H107.143V23.1059H104.391V11.8261H102.183V9.17629H104.391V6.67127C104.391 5.83144 104.663 5.1364 105.222 4.58617C105.767 4.03593 106.469 3.74634 107.286 3.74634H110.038"
          fill="white"
        />
        <path
          d="M122.451 10.8992C121.161 9.56703 119.513 8.88647 117.52 8.88647C115.514 8.88647 113.865 9.55255 112.561 10.8992C111.271 12.2313 110.626 13.9834 110.626 16.1409C110.626 18.2984 111.271 20.036 112.561 21.3826C113.865 22.7148 115.528 23.3953 117.52 23.3953C119.527 23.3953 121.175 22.7292 122.451 21.3826C123.755 20.0505 124.415 18.2984 124.415 16.1409C124.4 13.9834 123.755 12.2458 122.451 10.8992ZM120.459 19.6016C119.685 20.4559 118.696 20.8903 117.506 20.8903C116.316 20.8903 115.313 20.4559 114.525 19.6016C113.751 18.7328 113.364 17.5744 113.364 16.1409C113.364 14.7074 113.751 13.5635 114.525 12.7092C115.313 11.8404 116.302 11.406 117.506 11.406C118.696 11.406 119.685 11.8404 120.459 12.7092C121.247 13.5635 121.648 14.7074 121.648 16.1409C121.648 17.5744 121.247 18.7183 120.459 19.6016Z"
          fill="white"
        />
        <path
          d="M134.706 11.6813H132.427C130.492 11.6813 129.532 12.6514 129.532 14.5773V23.1059H126.78V9.17627H129.13L129.403 10.436H129.546C129.56 10.4215 129.618 10.3491 129.718 10.2478C129.833 10.1319 129.89 10.0595 129.904 10.0306C129.947 9.98714 130.019 9.92922 130.119 9.84234C130.234 9.72651 130.32 9.65411 130.392 9.62515C130.463 9.58171 130.564 9.53827 130.693 9.48035C130.822 9.40795 130.951 9.36451 131.08 9.33555C131.223 9.29211 131.395 9.27763 131.581 9.24867C131.768 9.20523 131.954 9.19075 132.155 9.19075H134.634"
          fill="white"
        />
      </g>
      <defs></defs>
    </svg>
  )
}
